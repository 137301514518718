import React from 'react';
import './AppearanceCard.scss';

export const AppearanceCard = ({
  selected,
  onClick,
  imageSrc,
  label,
  imageClass = '',
  imageHeight,
  deleted,
}) => {
  const cardStyle = selected ? { outline: `7px solid darkorange` } : {};

  return (
    <div
      className={`appearance-card ${deleted ? ' deleted' : ''}`}
      style={cardStyle}
      role="button"
      onClick={onClick}
    >
      <div className="info-tour"></div>
      <div className="card-body text-center">
        <img
          style={{ height: imageHeight || '250px' }}
          src={imageSrc}
          alt={label}
          className={`user-drag-none ${imageClass}`}
        />
      </div>

      <div className="card-footer text-center">
        <span>{label}</span>
      </div>
    </div>
  );
};
