import { getSectionNameForType } from '../components/BannerSelector';
import Banner from '../models/Banner';
import {
  appendHealthPSAToBlock,
  createBlockFromTitlePage,
  createCocktailsBlock,
  createDrinksBlock,
  createEmptyBlock,
  createSimulator,
} from '../Renderer/helpers';
import checkOverflow from './checkOverflow';

import { BEVERAGE_TYPES, BLOCK_FORMATS, EMPTY_BLOCK } from './constants';

import { injectElementIntoContainer } from './injectElementIntoContainer';

const { COCKTAIL, DRINK } = BEVERAGE_TYPES;

const getComputedDimensions = element => {
  const cs = window.getComputedStyle(element);

  const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
  const paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

  const borderX =
    parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
  const borderY =
    parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

  // Element width and height minus padding and border
  const width = element.offsetWidth - paddingX - borderX;
  const height = element.offsetHeight - paddingY - borderY;

  return { width, height };
};

export const generateBlocks = ({
  selectedTitlePage,
  drinkMenu,

  blockifiedDrinkCategories,
  blockifiedPhotoCocktailsAndCocktailCategories,

  photoCocktailBanners,
  cocktailBanners,
  drinkBanners,
  emptyBlockBanners,

  bannersSearchPool,

  photoCocktailBannersAllowed,
}) => {
  const usingA5 = selectedTitlePage.blockFormat === BLOCK_FORMATS.A5;
  const isSummerMenu = selectedTitlePage.blockFormat === 'A5_summer';

  const injectBannerIntoBlock = (banner, block, isDrinkBlock = false) => {
    let bannerContainer;

    if (isDrinkBlock && usingA5 && !banner.fullWidth) {
      // block(.block) -> firstChild(.content-container)
      //               -> lastChild (2nd .column) -> lastChild (.banner-container)
      bannerContainer = block.firstChild.lastChild.lastChild;
    } else {
      // block (.block) ->
      //       children (.content-container, .banner-container, ?.health-psa) -> [1] (.banner-container)
      bannerContainer = block.children[1];
    }

    const { width: bannerContainerWidth } = getComputedDimensions(
      bannerContainer
    );

    banner.scaleToWidth(bannerContainerWidth);

    return injectElementIntoContainer(banner.domNode, bannerContainer);
  };

  let blocks = [];

  const unfitBanners = [];

  const unfitSearchPoolBanners = [];

  const titlePageBlock = createBlockFromTitlePage({
    titlePage: selectedTitlePage,
    drinkMenu,
  });

  if (!isSummerMenu) {
    blocks.push(titlePageBlock);
  }

  const simulator = createSimulator(selectedTitlePage.blockFormat);
  document.body.appendChild(simulator);

  const drinkBlocks = blockifiedDrinkCategories.map(blockfulOfItems =>
    createDrinksBlock(blockfulOfItems)
  );

  if (drinkBlocks.length) {
    const lastBlock = drinkBlocks[drinkBlocks.length - 1].cloneNode(true);
    simulator.appendChild(lastBlock);

    drinkBanners.forEach(banner => {
      const removeTheBanner = injectBannerIntoBlock(banner, lastBlock, true);

      if (checkOverflow(lastBlock)) {
        removeTheBanner();
        unfitBanners.push(banner);
      }
    });

    // run through all possible banners to filter out the banners we cannot fit
    bannersSearchPool.forEach(banner => {
      const removeTheBanner = injectBannerIntoBlock(banner, lastBlock, true);

      if (checkOverflow(lastBlock)) {
        /*
         mark search pool banners that didn't fit here with their beverage type

         Far from excellent design, but we were short on time with this.
         */

        const unfitBanner = new Banner({
          ...banner,
          section: getSectionNameForType(DRINK),
        });

        unfitSearchPoolBanners.push(unfitBanner);
      }

      removeTheBanner();
    });

    drinkBlocks[drinkBlocks.length - 1] = lastBlock;

    simulator.removeChild(lastBlock);
  }

  blocks = blocks.concat(drinkBlocks);

  const cocktailBlocks = blockifiedPhotoCocktailsAndCocktailCategories.map(
    blockfulOfCocktails => createCocktailsBlock(blockfulOfCocktails)
  );

  if (cocktailBlocks.length) {
    if (!isSummerMenu) appendHealthPSAToBlock(cocktailBlocks[0]);

    // (photo)cocktail banners
    const lastBlock = cocktailBlocks[cocktailBlocks.length - 1].cloneNode(true);
    simulator.appendChild(lastBlock);

    const banners = photoCocktailBannersAllowed
      ? photoCocktailBanners
      : cocktailBanners;

    banners.forEach(banner => {
      const removeTheBanner = injectBannerIntoBlock(banner, lastBlock);

      if (checkOverflow(lastBlock)) {
        removeTheBanner();
        unfitBanners.push(banner);
      }
    });

    bannersSearchPool.forEach(banner => {
      const removeTheBanner = injectBannerIntoBlock(banner, lastBlock);

      if (checkOverflow(lastBlock)) {
        /*
         mark search pool banners that didn't fit here with their beverage type

         Again, far from excellent design, but we were short on time with this.

         Please note, that all (photo)cocktail banners are marked with 'cocktail' beverage type.
         */

        const unfitBanner = new Banner({
          ...banner,
          section: getSectionNameForType(COCKTAIL),
        });

        unfitSearchPoolBanners.push(unfitBanner);
      }

      removeTheBanner();
    });

    cocktailBlocks[cocktailBlocks.length - 1] = lastBlock;

    simulator.removeChild(lastBlock);
  }

  let hasEmptyBlock = false;

  if (isSummerMenu) {
    blocks = blocks.concat(cocktailBlocks.slice());
  } else {
    // cocktail blocks should be added in reverse order
    blocks = blocks.concat(cocktailBlocks.slice().reverse());
  }

  // insert an empty block to ensure even number of blocks
  if (blocks.length % 2) {
    hasEmptyBlock = true;
    const emptyBlock = createEmptyBlock();

    // insert banners
    simulator.appendChild(emptyBlock);

    emptyBlockBanners.forEach(banner => {
      const removeTheBanner = injectBannerIntoBlock(banner, emptyBlock);

      if (checkOverflow(emptyBlock)) {
        removeTheBanner();
        unfitBanners.push(banner);
      }
    });

    bannersSearchPool.forEach(banner => {
      const removeTheBanner = injectBannerIntoBlock(banner, emptyBlock);

      if (checkOverflow(emptyBlock)) {
        const unfitBanner = new Banner({
          ...banner,
          section: EMPTY_BLOCK,
        });

        unfitSearchPoolBanners.push(unfitBanner);
      }

      removeTheBanner();
    });

    simulator.removeChild(emptyBlock);

    if (isSummerMenu) {
      blocks.push(emptyBlock);
    } else {
      // add just before cocktailBlocks
      blocks.splice(-cocktailBlocks.length, 0, emptyBlock);
    }
  }

  document.body.removeChild(simulator);

  return [blocks, unfitBanners, unfitSearchPoolBanners, hasEmptyBlock];
};
