import React, { useMemo } from 'react';
import { camel, deserialise } from 'kitsu-core';

import { deserializeObject } from '../lib/deserialize';

const DataContext = React.createContext({});

export default DataContext;

export const DataProvider = ({ children, masterAppProps }) => {
  const starterData = useMemo(
    () => {
      let configData = Object.entries(masterAppProps)
        .filter(([key]) =>
          [
            'banners',
            'cocktail_categories',
            'cocktails',
            'drink_categories',
            'drink_menu',
            'drinks',
            'photo_cocktails',
            'title_pages',
            'price_list',
            'products',
            'currency_config'
          ].includes(key)
        )
        .reduce((res, [key, value], index, arr) => {
          key = camel(key);

          const { data } = deserialise(value);

          if (data === null) {
            if (
              [
                'drinks',
                'cocktails',
                'photoCocktails',
                'drinkCategories',
                'cocktailCategories',
              ].includes(key)
            ) {
              return { ...res, [key]: [] };
            } else {
              return { ...res, [key]: null };
            }
          }

          let deserializedValue;

          if (Array.isArray(data)) {
            deserializedValue = data.map(item =>
              typeof item === 'object' ? deserializeObject(item) : item
            );
          } else if (typeof data === 'object') {
            deserializedValue = deserializeObject(data);
          } else {
            // just pass on as is
            deserializedValue = data;
          }

          return { ...res, [key]: deserializedValue };
        }, {});

        return {
          ...configData,
          locale: masterAppProps.locale,
        };
    },
    [masterAppProps]
  );

  return (
    <DataContext.Provider value={starterData}>{children}</DataContext.Provider>
  );
};
