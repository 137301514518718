import React from 'react';

import { useTranslation } from 'react-i18next';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import AddPhotoCocktailForm from '../components/AddPhotoCocktailForm';
import BannerSelector from '../components/BannerSelector';
import PhotoCocktailComponent from '../components/PhotoCocktailComponent';
import useMenuContext from '../hooks/useMenuContext';
import { useBlocksContext } from '../hooks/useBlocksContext';
import { useConfigContext } from '../hooks/useConfigContext';
import useSearchPoolContext from '../hooks/useSearchPoolContext';
import { BEVERAGE_TYPES } from '../lib/constants';

import './PhotoCocktailScreen.scss';

const { PHOTO_COCKTAIL } = BEVERAGE_TYPES;

const SortablePhotoCocktailWrapper = SortableElement(({ children }) => (
  <div className="col-12 col-lg-6 mb-4">{children}</div>
));

const SortablePhotoCocktailsContainer = SortableContainer(({ children }) => {
  return <div className="row">{children}</div>;
});

const PHOTO_COCKTAILS_PER_PAGE = 8;
const PHOTO_COCKTAILS_PER_PAGE_SUMMER_MENU = 4;
const MAX_PAGES_SUMMER_MENU = 3;

export default function PhotoCocktailScreen() {
  const { t } = useTranslation();

  const { unfitBanners } = useBlocksContext();

  const {
    selectedBlockFormat,
  } = useConfigContext();

  let maxCocktails = 0; // 0 = no limit
  if (selectedBlockFormat === 'A5_summer') {
    maxCocktails = PHOTO_COCKTAILS_PER_PAGE_SUMMER_MENU * MAX_PAGES_SUMMER_MENU;
  }

  const cocktailsPerPage = selectedBlockFormat === 'A5_summer' ? PHOTO_COCKTAILS_PER_PAGE_SUMMER_MENU : PHOTO_COCKTAILS_PER_PAGE;
  
  const {
    addBeverage,
    deleteBeverage,
    updateBeverage,
    movePhotoCocktail,
    photoCocktailBanners,

    photoCocktails,

    photoCocktailBannersAllowed,
  } = useMenuContext();

  const { beveragesSearchPool } = useSearchPoolContext();

  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    try {
      await movePhotoCocktail(oldIndex, newIndex);
    } catch (error) {
      window.alert(t('itemUpdateError_plural'));
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <SortablePhotoCocktailsContainer
            axis={'xy'}
            useDragHandle
            onSortEnd={handleSortEnd}
          >
            {photoCocktails.length ? (
              photoCocktails.map((photoCocktail, index) => (
                <React.Fragment key={photoCocktail.id}>
                  <SortablePhotoCocktailWrapper
                    index={index}
                    disabled={photoCocktail.required}
                  >
                    <PhotoCocktailComponent
                      photoCocktail={photoCocktail}
                      onDelete={deleteBeverage}
                      onUpdate={updateBeverage}
                    />
                  </SortablePhotoCocktailWrapper>

                  {Boolean(index) && !((index + 1) % cocktailsPerPage) && (
                    <div className="page-divider mb-4 bg-info col-12">
                      <div className="badge badge-pill badge-info page-divider__inner text-white">
                        {t('page')}: {(index + 1) / cocktailsPerPage}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))
            ) : (
              <div className="col-12">
                <p className="text-center">{t('emptyList')}</p>
              </div>
            )}
          </SortablePhotoCocktailsContainer>

          <AddPhotoCocktailForm
            onSubmit={addBeverage}
            searchPool={beveragesSearchPool[PHOTO_COCKTAIL]}
            hideForm={maxCocktails && photoCocktails.length >= maxCocktails}
          />

          {photoCocktailBannersAllowed && (
            <BannerSelector
              banners={photoCocktailBanners}
              type={PHOTO_COCKTAIL}
              unfitBanners={unfitBanners}
            />
          )}
        </div>
      </div>
    </div>
  );
}
