import React, { useEffect, useMemo, useState } from 'react';
import useAPI from '../hooks/useAPI';
import { useConfigContext } from '../hooks/useConfigContext';
import useMenuContext from '../hooks/useMenuContext';
import useSearchPoolContext from '../hooks/useSearchPoolContext';
import { BEVERAGE_TYPES } from '../lib/constants';
import { constructArrayFromIdsAndHash } from '../lib/constructArrayFromIdsAndHash';
import { generateBlocks } from '../lib/generateBlocks';
import { producePreviewHTMLBodyFromBlocks } from '../lib/producePreviewHTMLBodyFromBlocks';
import { wrapBlocksIntoPages } from '../lib/wrapBlocksIntoPages';
import Category from '../models/Category';

import paginateDrinkCategories from '../utils/paginateDrinkCategories';
import paginatePhotoCocktailsAndCocktailCategories from '../utils/paginatePhotoCocktailsAndCocktailCategories';

const BlocksContext = React.createContext({});

export default BlocksContext;

export const BlocksProvider = ({ children }) => {
  const API = useAPI();

  const { bannersSearchPool } = useSearchPoolContext()

  const [timeoutTrigger, setTimeoutTrigger] = useState(false);

  const {
    selectedTitlePage,
    drinkMenu,
    selectedBlockFormat,
  } = useConfigContext();

  const {
    photoCocktails,
    cocktailCategories,
    drinkCategories,
    menuItems,

    photoCocktailBanners,
    cocktailBanners,
    drinkBanners,
    emptyBlockBanners,

    photoCocktailBannersAllowed,
  } = useMenuContext();

  const cocktailCategoriesFilledWithItems = useMemo(
    () =>
      cocktailCategories.map(c => {
        const items = constructArrayFromIdsAndHash(c.beverageIds, menuItems);

        return new Category({ ...c, items });
      }),
    [cocktailCategories, menuItems]
  );

  const drinkCategoriesFilledWithItems = useMemo(
    () =>
      drinkCategories.map(c => {
        const items = constructArrayFromIdsAndHash(c.beverageIds, menuItems);

        return new Category({ ...c, items });
      }),
    [drinkCategories, menuItems]
  );

  const blockifiedPhotoCocktailsAndCocktailCategories = useMemo(
    () =>
      paginatePhotoCocktailsAndCocktailCategories(
        photoCocktails,
        cocktailCategoriesFilledWithItems,
        selectedBlockFormat
      ),
    [photoCocktails, cocktailCategoriesFilledWithItems, selectedBlockFormat, timeoutTrigger]
  );

  const { blockifiedDrinkCategories } = useMemo(() => {
    return paginateDrinkCategories(
      drinkCategoriesFilledWithItems,
      BEVERAGE_TYPES.DRINK,
      selectedBlockFormat
    );
  }, [drinkCategoriesFilledWithItems, drinkBanners, selectedBlockFormat, timeoutTrigger ]);

  const [blocks, unfitBanners, unfitSearchPoolBanners, hasEmptyBlock] = useMemo(() => {
    return generateBlocks({
      selectedTitlePage,
      drinkMenu,

      blockifiedDrinkCategories,
      blockifiedPhotoCocktailsAndCocktailCategories,

      photoCocktailBanners,
      cocktailBanners,
      drinkBanners,
      emptyBlockBanners,

      bannersSearchPool,

      photoCocktailBannersAllowed,
    });
  }, [
    selectedTitlePage,
    drinkMenu,

    blockifiedDrinkCategories,
    blockifiedPhotoCocktailsAndCocktailCategories,

    photoCocktailBanners,
    cocktailBanners,
    drinkBanners,

    bannersSearchPool,

    photoCocktailBannersAllowed,
  ]);

  const blockCount = blocks.length;

  useEffect(() => {
    const t = setTimeout(() => setTimeoutTrigger(true), 300);
    return () => clearTimeout(t);
  }, [])

  useEffect(() => {
    // that's where the magic happens
    const sendHtmlBody = async () => {
      try {
        const [pages, htmlPageWidth, htmlPageHeight] = wrapBlocksIntoPages(
          blocks,
          selectedBlockFormat
        );

        const previewHtmlBody = producePreviewHTMLBodyFromBlocks(blocks, selectedBlockFormat, selectedTitlePage);

        const htmlBody = pages.reduce((res, page) => res + page.outerHTML, '');

        await API.updateDrinkMenuAttributes({
          htmlBody,
          previewHtmlBody,

          blockCount,
          titlePageId: selectedTitlePage.id,
          name: drinkMenu.name,
          address: drinkMenu.address,
          htmlPageWidth,
          htmlPageHeight,
        });
      } catch (e) {
        console.error(e);
      }
    };

    sendHtmlBody();
  }, [blocks]);

  //console.log(blocks);

  return (
    <BlocksContext.Provider
      value={{
        blockifiedPhotoCocktailsAndCocktailCategories,
        blockifiedDrinkCategories,
        blocks,
        blockCount,

        unfitBanners,
        unfitSearchPoolBanners,
        hasEmptyBlock
      }}
    >
      {children}
    </BlocksContext.Provider>
  );
};
