import {
  appendHealthPSAToBlock,
  createBlockWithItems,
  createSimulator,
} from '../Renderer/helpers';

import Category from '../models/Category';
import checkOverflow from '../lib/checkOverflow';

export const ITEMS_IN_CATEGORY_BEFORE_PAGE_BREAK = 2;

const categoriesToHunksReducer = (res, category) => {
  const hunkedCategory = category.items.reduce(
    (res, item, index) => {
      if (index < ITEMS_IN_CATEGORY_BEFORE_PAGE_BREAK) {
        res[0].push(item);
      } else {
        res.push([item]);
      }

      return res;
    },
    // 2D array. 1st array will be for [title, item, item]
    //           2nd onwards will be just [item], [item]....
    [[category]]
  );

  return [...res, ...hunkedCategory];
};

export const simulateBlockWithItems = (items, simulator, renderFunc) => {
  simulator.innerHTML = '';

  const simulatedBlock = renderFunc
    ? renderFunc(items)
    : createBlockWithItems(items);

  simulator.appendChild(simulatedBlock);
  return simulatedBlock;
};

export default function paginatePhotoCocktailsAndCocktailCategories(
  photoCocktails,
  cocktailCategories,
  blockFormat
) {
  const isSummerMenu = blockFormat === 'A5_summer';
  // filter out item-less categories
  // cocktailCategories = [...cocktailCategories].filter(
  //   category => category.items.length
  // );

  // prevent aliasing issues
  photoCocktails = [...photoCocktails];

  let blockifiedPhotoCocktails = [];
  let blockifiedPhotoCocktailsAndCocktailCategories = [];

  if (!cocktailCategories.length && !photoCocktails.length) return [];

  const simulator = createSimulator(blockFormat);

  document.body.appendChild(simulator);

  /* photo cocktails pagination */

  let index = 0;
  blockifiedPhotoCocktails = [photoCocktails];

  let block = simulateBlockWithItems(
    blockifiedPhotoCocktails[index],
    simulator
  );

  if (!isSummerMenu) {
    // append it to the very first block for accurate pagination
    appendHealthPSAToBlock(block);
  }

  while (checkOverflow(block)) {
    if (!blockifiedPhotoCocktails[index + 1])
      blockifiedPhotoCocktails[index + 1] = [];

    blockifiedPhotoCocktails[index + 1].unshift(
      blockifiedPhotoCocktails[index].pop()
    );

    block = simulateBlockWithItems(blockifiedPhotoCocktails[index], simulator);
    if (index === 0 && !isSummerMenu) appendHealthPSAToBlock(block);

    // if after moving the item it still overflows, try next iteration
    if (checkOverflow(block)) continue;

    // but if it helped, set block to the next and increase the index
    block = simulateBlockWithItems(
      blockifiedPhotoCocktails[index + 1],
      simulator
    );
    index += 1;
  }

  /* cocktail categories pagination */

  // package the last block of photoCocktails as hunks
  const lastBlockOfPhotoCocktailsAsHunks = blockifiedPhotoCocktails[
    blockifiedPhotoCocktails.length - 1
  ].map(item => [item]);

  index = 0; // reset the index

  const categoryHunks = cocktailCategories.reduce(categoriesToHunksReducer, []);

  let blockfulsOfCategoryHunks = [
    [...lastBlockOfPhotoCocktailsAsHunks, ...categoryHunks],
  ];

  block = simulateBlockWithItems(
    blockfulsOfCategoryHunks[index].flat(),
    simulator
  );

  if (blockifiedPhotoCocktails.length === 1 && !isSummerMenu) appendHealthPSAToBlock(block);

  while (checkOverflow(block)) {
    if (!blockfulsOfCategoryHunks[index + 1])
      blockfulsOfCategoryHunks[index + 1] = [];

    const lastHunkOnThePage = blockfulsOfCategoryHunks[index].pop();

    blockfulsOfCategoryHunks[index + 1].unshift(lastHunkOnThePage); // append to the next block

    block = simulateBlockWithItems(
      blockfulsOfCategoryHunks[index].flat(),
      simulator
    );
    if (blockifiedPhotoCocktails.length === 1 && index === 0  && !isSummerMenu)
      appendHealthPSAToBlock(block);

    /* if after moving the item it still overflows, try next iteration*/
    if (checkOverflow(block)) continue;

    /*
     If the next block starts with a 'lonely' item hunk prepend a category title to it.
     [item]  ->   [title, item]
    */
    const topCategoryHunkOfTheLastBlock =
      blockfulsOfCategoryHunks[index + 1][0];

    if (
      topCategoryHunkOfTheLastBlock.length === 1 &&
      topCategoryHunkOfTheLastBlock[0].constructor !== Category
    ) {
      // Search current block's hunks from the end to the beginning for the last category title.
      let lastCategoryTitle;
      let searchedHunkIndex = blockfulsOfCategoryHunks[index].length - 1; // last index

      // Go through the indices until we find a hunk that has more than one item.
      while (blockfulsOfCategoryHunks[index][searchedHunkIndex].length === 1) {
        searchedHunkIndex -= 1;
      }

      if (
        blockfulsOfCategoryHunks[index][searchedHunkIndex].length &&
        blockfulsOfCategoryHunks[index][searchedHunkIndex][0]
      ) {
        lastCategoryTitle =
          blockfulsOfCategoryHunks[index][searchedHunkIndex][0];

        blockfulsOfCategoryHunks[index + 1][0].unshift(lastCategoryTitle);
      }
    }

    // but if it helped, set block to the next and increase the index
    block = simulateBlockWithItems(
      blockfulsOfCategoryHunks[index + 1].flat(),
      simulator
    );
    index += 1;
  }

  document.body.removeChild(simulator);

  let blockifiedCategories = blockfulsOfCategoryHunks.map(blockfulOfHunks =>
    blockfulOfHunks.flat()
  );

  const blockifiedPhotoCocktailsAsDomWithoutLastPage = blockifiedPhotoCocktails
    // .map(page => page.map(item => item.domNode))
    // chop off the last block, because it's in the blockifiedCategories now
    .slice(0, blockifiedPhotoCocktails.length - 1);

  blockifiedPhotoCocktailsAndCocktailCategories = [
    ...blockifiedPhotoCocktailsAsDomWithoutLastPage,
    ...blockifiedCategories,
  ];

  return blockifiedPhotoCocktailsAndCocktailCategories;
}
