import './AppearanceScreen.scss';

import {
  BEVERAGE_TYPES,
  BLOCK_FORMATS,
  BLOCK_FORMAT_IMAGES,
} from '../lib/constants';
import React, { useEffect, useRef } from 'react';

import { AppearanceCard } from './AppearanceCard';
import { AppearanceInputField } from '../components/AppearanceInputField';
import { DirectUpload } from '@rails/activestorage';
import useAPI from '../hooks/useAPI';
import { useConfigContext } from '../hooks/useConfigContext';
import useDataContext from '../hooks/useDataContext';
import useLoader from '../hooks/useLoader';
import useMenuContext from '../hooks/useMenuContext';
import useNavigationContext from '../hooks/useNavigationContext';
import { useTranslation } from 'react-i18next';

const fields = ['name', 'address'];

export default function AppearanceScreen() {
  const {
    selectedTitlePage,
    setSelectedTitlePage,
    setSelectedBlockFormat,
    selectedBlockFormat,
    drinkMenu,
    setDrinkMenu,
  } = useConfigContext();

  const fileInputRef = useRef();

  const { setCurrentRoute } = useNavigationContext();

  const { deleteAllBanners } = useMenuContext();

  const { logoPath } = drinkMenu;

  const { titlePages } = useDataContext();

  console.log('titlePages', titlePages);
  console.log('selectedTitlePage', selectedTitlePage);
  console.log('selectedBlockFormat', selectedBlockFormat);

  const { t } = useTranslation();

  const fileLoader = useLoader();

  const { updateDrinkMenuLogo } = useAPI();
  const handleBlockFormatChange = async newBlockFormat => {
    const similarTitlePage = titlePages.find(page => {
      const { template, variant } = selectedTitlePage;
      return (
        page.template === template &&
        page.variant === variant &&
        page.blockFormat === newBlockFormat
      );
    });

    if (similarTitlePage) {
      setSelectedTitlePage(similarTitlePage);
    } else {
      const firstTitlePageWithCorrectBlockFormat = titlePages.find(page => {
        return page.blockFormat === newBlockFormat;
      });
      setSelectedTitlePage(firstTitlePageWithCorrectBlockFormat);
    }

    setSelectedBlockFormat(newBlockFormat);

    // delete all banners when we change blockFormat
    try {
      await deleteAllBanners();
    } catch (e) {
      console.error(e);
    }
  };

  const handleTitlePageChange = async titlePage => {
    if (titlePage.deleted) {
      alert(t('AppearanceScreen.titlePageDeleted'));
      return;
    }
    // if we're switching to honey, we shall delete all banners
    // of course it would be nicer to refactor this but GOD we're short on time with this
    const { blockFormat, template, variant } = titlePage;

    if (template !== selectedTitlePage.template || variant === 'honey') {
      try {
        await deleteAllBanners();
      } catch (e) {
        console.error(e);
      }
    }

    setSelectedTitlePage(titlePage);
    setSelectedBlockFormat(blockFormat);
  };

  const titlePagesFilteredByBlockFormat = titlePages.filter(
    page => page.blockFormat === selectedBlockFormat
  );

  const handleFieldSubmit = field => async value => {
    setDrinkMenu(old => ({ ...old, [field]: value }));
  };

  // // is never used
  // const menuItemsArray = useMemo(() => Object.values(menuItems), [menuItems]);

  // const canPrefill = useMemo(
  //   () => !menuItemsArray.filter(item => !item.required).length,
  //   [menuItemsArray]
  // );

  useEffect(() => {
    const UPLOAD_URL = '/rails/active_storage/direct_uploads';

    // logo file upload
    const { current: input } = fileInputRef;

    const uploadFile = file => {
      fileLoader.startLoading();

      const upload = new DirectUpload(file, UPLOAD_URL);

      upload.create((error, blob) => {
        if (error) {
          fileLoader.setError(t('logoUpdateError'));
        } else {
          const { signed_id: logoFileId } = blob;

          updateDrinkMenuLogo(logoFileId)
            .then(() => {
              fileLoader.stopLoading();
            })
            .catch(error => {
              console.log('error: ', error);
              fileLoader.setError(t('logoUpdateError'));
            });
        }
      });
    };

    input.addEventListener('change', event => {
      const file = input.files[0];
      uploadFile(file);

      // clear file input
      input.value = null;
    });
  }, []);

  const goToNextStep = () => {
    if (selectedBlockFormat === 'A5_summer') {
      setCurrentRoute(BEVERAGE_TYPES.PHOTO_COCKTAIL);
    } else {
      setCurrentRoute(BEVERAGE_TYPES.DRINK);
    }
  }

  const _removeLogo = () => {
    updateDrinkMenuLogo(null);
  };

  const _viewRemoveLogo = () => (
    <div className="col-12 m-3">
      <div className="row m-0">
        <h4>{t('AppearanceScreen.viewRemovelogo')}</h4>
        {logoPath && (
          <button className="btn btn-sm btn-danger ml-3" onClick={_removeLogo}>
            <i className="fa fa-fw fa-trash"></i>
          </button>
        )}
      </div>
      <div>
        {logoPath ? (
          <div>
            <img
              style={{ height: '210px' }}
              src={logoPath}
              alt={'logo drink menu'}
            />
          </div>
        ) : (
          <h5> {t('AppearanceScreen.noLogo')}</h5>
        )}
      </div>
    </div>
  );

  const _viewPage = () => (
    <>
      <div className="row mb-5">
        <div className="col-12 mb-2">
          <h4>{t('AppearanceScreen.selectBlockFormat')}</h4>
        </div>

        {Object.entries(BLOCK_FORMATS).map(([format, formatLabel]) => {
          const selected = format === selectedBlockFormat;
          //TODO:remove after add A4(A5-2) format
          if (format === 'A4') return null;
          if (selectedBlockFormat === 'A5_summer' && format !== 'A5_summer') return null;
          if (selectedBlockFormat !== 'A5_summer' && format === 'A5_summer') return null;

          return (
            <div className="col-lg-6 col-12 mb-4" key={format}>
              <AppearanceCard
                selected={selected}
                onClick={() => handleBlockFormatChange(format)}
                label={formatLabel}
                imageSrc={BLOCK_FORMAT_IMAGES[format]}
                imageHeight="340px"
                imageClass="w-100 object-fit-contain"
              />
            </div>
          );
        })}
      </div>

      <div className="row mb-4">
        <div className="col">
          <div className="row">
            <div className="col-12 mb-2">
              <h4>{t('AppearanceScreen.selectTitlePage')}</h4>
            </div>

            {titlePagesFilteredByBlockFormat.sort((a,b) => a.template.localeCompare(b.template, 'en')).map(titlePage => {
              const { id, formattedName, imagePreviewPath, code, deleted } = titlePage;

              const selected = id === selectedTitlePage.id;

              if (titlePage.deleted && !selected) return null;

              return (
                <div className="col-lg-3 col-6 mb-4" key={id}>
                  <AppearanceCard
                    selected={selected}
                    deleted={deleted}
                    label={formattedName}
                    onClick={() => handleTitlePageChange(titlePage)}
                    imageSrc={imagePreviewPath}
                    imageClass={`title-page-button--${code} object-fit-contain w-100`}
                  />
                </div>
              );
            })}

            {_viewRemoveLogo()}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="container">
      {_viewPage()}

      <div className="row mb-4" style={{ display: selectedBlockFormat === 'A5_summer' ? 'none' : 'flex' }}>
        <div className="col-12">
          <h4 className="mb-2">
            {t('AppearanceScreen.enterTextsToDisplayInTitlePage')}
          </h4>

          {fields.map(field => (
            <AppearanceInputField
              init={drinkMenu[field] || ''}
              key={field}
              field={field}
              onSubmit={handleFieldSubmit(field)}
            />
          ))}

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="logo_file">
                {t('logo.logo')}
              </label>
              <input type="file" className="d-none" ref={fileInputRef} />
              <button
                id="logo_file"
                type="button"
                className="btn text-initial btn-outline-secondary"
                onClick={() =>
                  fileInputRef.current && fileInputRef.current.click()
                }
              >
                {t('chooseFile')}
              </button>
            </div>
            <div className="rounded-right border d-flex flex-1 align-items-center p-0 pl-2 pr-2">
              {fileLoader.loading && (
                <span className="text-info">{t('logo.isUploading')}</span>
              )}
              {fileLoader.success && (
                <span className="text-success">
                  {t('logo.successfullyUpdated')}
                </span>
              )}
              {fileLoader.error && (
                <span className="text-danger">{fileLoader.error}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col d-flex align-items-baseline">
          <button
            className="btn btn-primary btn-lg"
            onClick={goToNextStep}
          >
            {t('AppearanceScreen.createMenu')}
          </button>
        </div>
      </div>
    </div>
  );
}
