import {
  convertPixelsToMillimeters,
  createSimulator,
} from '../Renderer/helpers';

export const wrapBlocksIntoPages = (blocks, blockFormat) => {
  const isSummerMenu = blockFormat === 'A5_summer';
  
  blocks = blocks.map(b => b.cloneNode(true));

  const length = blocks.length;

  const remainder = length % 4;
  const roundedLength = length - remainder;

  // blocks = [1,2,3,4,5,6,7,8,9,10]

  let backBlocks;
  let frontBlocks;

  if (isSummerMenu) {
    backBlocks = blocks.splice(0, 1);
    frontBlocks = blocks.splice(0, 1);
  } else {
    backBlocks = blocks.splice(0, 1);
    // [2,3,4,5,6,7,8,9,10]

    frontBlocks = blocks.splice(0, roundedLength / 2);
    // [6,7,8,9,10]

    backBlocks = backBlocks.concat(blocks.splice(0, roundedLength / 2 - 1));
    // [9,10]

    if (remainder) {
      backBlocks.push(blocks.splice(0, 1)[0]);
      frontBlocks.push(blocks.splice(0, 1)[0]);
    }
  }

  const page = document.createElement('div');
  page.classList.add('page');

  const front = page.cloneNode(true);
  front.classList.add('front');

  const back = page.cloneNode(true);
  back.classList.add('back');

  // [1,2,3,..last] -> [..last,3,2,1]
  // because that's how we print it
  backBlocks.reverse();

  frontBlocks.filter(Boolean).forEach(b => front.appendChild(b));
  backBlocks.filter(Boolean).forEach(b => back.appendChild(b));

  const simulator = createSimulator(blockFormat);
  document.body.appendChild(simulator);

  simulator.appendChild(back);

  const pageWidth = convertPixelsToMillimeters(back.clientWidth);
  const pageHeight = convertPixelsToMillimeters(back.clientHeight);

  // console.log('width', back.clientWidth, 'height', back.clientHeight);

  document.body.removeChild(simulator);

  // back.style.pageBreakAfter = 'always';

  return [[back, front], pageWidth, pageHeight];
};
